import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo } from 'react';

import { Typography } from '@sravni/react-design-system';

import Card from '@src/components/ProductList/components/Card';
import { CardV2 } from '@src/components/ProductList/components/CardV2';
import { NA_KARTU_PAGE_DEFAULT_AMOUNT, NA_KARTU_PAGE_DEFAULT_TERM } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { isNaKartuPage } from '@src/utils/routing';

import style from './styles.module.scss';

const { Heading } = Typography;

interface IProps {
    title?: string;
}

export const BestProducts: FC<IProps> = memo(({ title }) => {
    const bestProductList = useSelectCreditsList(ListKeys.BESTPRODUCTS);
    const items = bestProductList?.items || [];

    const { asPath } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');
    const isNewCardDesign = isNaKartuPage(pathWithoutQuery);

    const CardComponent = memo(isNewCardDesign ? CardV2 : Card);

    if (!items.length) {
        return null;
    }

    return (
        <div>
            <Heading className={style.title} level={2}>
                {title || 'Популярные предложения'}
            </Heading>
            <div className={style.cards}>
                {items.map((item, index) => (
                    <CardComponent
                        key={item._id}
                        offer={item}
                        position={index}
                        query={{ amount: NA_KARTU_PAGE_DEFAULT_AMOUNT, term: NA_KARTU_PAGE_DEFAULT_TERM }}
                    />
                ))}
            </div>
        </div>
    );
});
