import cn from 'classnames';
import React from 'react';

import { Breadcrumbs as BreadcrumbsBase } from './Breadcrumbs';
import styles from './styles.module.scss';

interface IItemProps {
    url?: string;
    title: string;
}

export interface IItemsProps {
    className?: string;
    homeTitle?: string;
    items: IItemProps[];
    isHomeVisible?: boolean;
}

export const Breadcrumbs: React.FC<IItemsProps> = ({ className, items, homeTitle, isHomeVisible = true }) => {
    if (!Array.isArray(items) || !items.length) {
        return null;
    }

    return (
        <div className={cn(styles.container, className)}>
            <div className={styles.content}>
                <BreadcrumbsBase items={items} homeTitle={homeTitle} isHomeVisible={isHomeVisible} />
            </div>
        </div>
    );
};
