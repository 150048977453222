import type { FC } from 'react';
import React, { memo } from 'react';

import { DisclaimerHelper } from '@src/helpers/disclaimerHelper';
import { typograf } from '@src/helpers/typograf';

import styles from './styles.module.scss';

const DISCLAIMER_TEXT = [
    {
        id: 1,
        text:
            'Сервис Sravni.ru бесплатно предоставляет информацию о займах. Процентные ставки и срок погашения займа могут отличаться, т.к. зависят от выбранной вами микрофинансовой организации (МФО). Ставки по займам могут составлять от 0% до 0,8% в день (максимальный процент по займу составит 292% годовых). Срок у данных займов может быть от 61 дня до 365 дней. Это означает, что минимальный срок погашения займа: 61 день. Максимальный срок погашения займа: 365 дней.',
    },
    {
        id: 2,
        text:
            'Пример расчета по займу на сумму 10 000 рублей сроком на 10 недель, т.е. на 70 дней, по ставке 0,75% в день - сумма процентов за весь период составит 5 250 рублей. Общая выплата будет равна 15 250 рублей.',
    },

    {
        id: 3,
        text:
            'При несоблюдении выплат в срок, предусмотренный в договоре, появляется штраф за просрочку займа. Проценты за пользование суммой займа при нарушении срока возврата займа и начисленных процентов на 10 дней (10% от суммы займа) – 1 000 рублей. Пеня на сумму займа в размере 20% годовых от суммы займа начисляется за просрочку возврата займа на 10 дней составит 51,7 рублей. Сумма к возврату при нарушении срока возврата займа и начисленных процентов на 10 дней: 15 250 + 1 000 + 51,7 = 16 301,7 рублей.',
    },

    {
        id: 4,
        text:
            'Информация о займах не является офертой или публичной офертой: конечные условия для выдачи конкретного займа определяются на сайте конкретного МФО в индивидуальном порядке.',
    },

    {
        id: 5,
        text:
            'Наши основные партнеры среди МФО: ООО МФК «Вэббанкир» (Рег.&nbsp;№ 2120177002077), ООО МФК «Мани Мен» (Рег.&nbsp;№ 2110177000478), ООО МФК «Займер» (Рег.&nbsp;№ 651303532004088), ООО МКК «КапиталЪ-НТ» (Рег.&nbsp;№ 001603465007766), ООО МКК «Русинтерфинанс» (Рег.&nbsp;№ 2120754001243).',
    },
];

export const Disclaimer: FC = memo(() => (
    <DisclaimerHelper>
        <div className={styles.text}>
            {DISCLAIMER_TEXT.map(({ id, text }) => (
                <p key={id}>{typograf.execute(text)}</p>
            ))}
        </div>
    </DisclaimerHelper>
));
