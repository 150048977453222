import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface IProps {
    className?: string;
    id?: string;
    href?: string;
    title: string;
    children: string;
    itemProp?: string;
    itemType?: string;
    itemRef?: string;
    itemScope?: boolean;
}

export const Item = (props: IProps) => {
    const { className, id, href, title, children, itemProp, itemType, itemRef, itemScope } = props;

    return (
        <a
            className={cn(styles.wrapper, className)}
            id={id}
            title={title}
            href={href}
            itemProp={itemProp}
            itemType={itemType}
            itemRef={itemRef}
            itemScope={itemScope}
        >
            {children}
        </a>
    );
};
