import type { FC } from 'react';
import React, { memo } from 'react';

import { DisclaimerHelper } from '@src/helpers/disclaimerHelper';
import { typograf } from '@src/helpers/typograf';
import { useBranding } from '@src/reducers/branding';

import styles from './styles.module.scss';

enum Brandings {
    Monesa = 'Монеза',
}

const brandingsTexts: { [key in Brandings]: string } = {
    [Brandings.Monesa]:
        '* ООО МКК «Макро» (ИНН: 7722355735, ОГРН: 1167746181790) Общие условия договора потребительского микрозайма, Правила предоставления потребительских микрозаймов и подробности об акции размещены на сайте www.moneza.ru. Займы предоставляются на сумму от 2000 до 30000 рублей на срок от 5 дней до 35 дней. Проценты за пользование займом составляют от 0 до 365 процентов годовых. Срок действия акции с 31.12.2018 г. по 31.12.2022 г.',
};

export const DisclaimerBranding: FC = memo(() => {
    const { organization } = useBranding() || {};
    const text = organization && brandingsTexts[organization.name as Brandings.Monesa];

    if (!text) {
        return null;
    }

    return (
        <DisclaimerHelper>
            <div className={styles.text}>{typograf.execute(text)}</div>
        </DisclaimerHelper>
    );
});
