import cn from 'classnames';
import type { MouseEventHandler } from 'react';
import React, { useCallback } from 'react';

import type { AffSub4 } from '@src/@types/microcredits';
import { PIXELS_TYPES } from '@src/constants/analytics';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import { sendAdvertisingButtonClickEvent } from '@src/helpers/analyticsEvents';
import { open } from '@src/helpers/navigationHelper';
import { usePixel } from '@src/hooks/usePixel';

import styles from './styles.module.scss';

interface IProps {
    analytics: IAnalyticsItem;
    source: string;
    position: number;
    className?: string;
}

export const Advertising: React.FC<IProps> = ({ children, analytics, source, className, position }) => {
    const { name, advertising, organization, eventAction, _id } = analytics;

    const link = advertising?.monetization?.pixels?.click;

    const affSub4: AffSub4 = {
        organizationName: organization.name,
        productName: name,
        organizationId: organization._id ?? '',
        productId: _id ?? '',
    };

    const href = usePixel(link, {
        source: `${source}|position${position}`,
        sub: advertising?.advSub,
        type: advertising?.productType,
        pixelType: PIXELS_TYPES.CLICK,
        affSub4,
    });

    const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            sendAdvertisingButtonClickEvent({ name, advertising, organization, eventAction });

            if (href)
                open({
                    href,
                    target: '_blank',
                    features: 'noopener',
                });
        },
        [advertising, eventAction, href, name, organization],
    );

    return (
        <a className={cn(styles.link, className)} onClick={handleClick}>
            {children}
        </a>
    );
};
